import React, {useState} from 'react';
import { Grid } from '@material-ui/core'
import { navigateTo } from 'gatsby'
import {Typography} from '@material-ui/core'
import { Card, Button } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme =>
  createStyles({
    container:{
      padding:theme.spacing(2)

    },
    title:{
      // paddingLeft:theme.spacing(1)
    },
    card:{
      width:'300px',
      height:'200px',
      padding:theme.spacing(2),
      position:'relative'
    },
    descr:{
      display:'block',
      paddingTop:theme.spacing(1),
      color: theme.palette.text.secondary
    },
    button:{
      position: 'absolute',
      bottom:theme.spacing(1),
      left:theme.spacing(1)
    }
  }))

const ArticleCard = ({children}) => {
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);
  const toggleElevation = () => { elevation === 1 ? setElevation(3) : setElevation(1)};
  return <Card className={classes.card}  elevation={elevation} onMouseEnter={toggleElevation} onMouseLeave={toggleElevation} >
    {children}
  </Card>
  }

const Articles = ({items}) => {
  const classes = useStyles();

  const handleClick = (item) => {
    navigateTo(`/${item.routeName}`)
  }

  return (
    <Grid container={true} spacing={2} className={classes.container}>
    {
      items.map(item => (
        <Grid item={true} >
          <ArticleCard  >
            <>
              <Typography variant="h5" className={classes.title}>{item.title.slice(0, 60) + '...'}</Typography>
              <Typography variant="caption" className={classes.descr}>{item.description.slice(0, 100) + '...'}</Typography>
              <Button className={classes.button} color={"primary"} onClick={() => handleClick(item)}>Подробнее</Button>

            </>
          </ArticleCard>
        </Grid>

      ))
    }
  </Grid>)
}

export default Articles;
