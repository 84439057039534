import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Layout from '../components/Layout/Layout'
import Articles from '../components/Layout/Articles'
import Seo from '../components/Seo/Seo'
import Search from '../components/Search'
import { PROJECT } from '../constants/project'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    search: {
      maxWidth: theme.spacing(80),
      padding: theme.spacing(20, 2, 2),
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(15, 2, 2),
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(10, 2, 2),
      },
    },
    searchMenu: {
      left: `${theme.spacing(-5.7)}px !important`,
      maxWidth: `${theme.spacing(80)}px !important`,
    },
    subtitle: {
      padding: theme.spacing(2),
      zIndex: 2,

      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h5,
      },
    },
    title: {
      padding: theme.spacing(2),
      zIndex: 2,

      [theme.breakpoints.down('sm')]: {
        ...theme.typography.h3,
      },

      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h4,
      },
    },
    img: {
      top: 0,
      width: '100%',
      position: 'absolute'
    }
  })
)

// export const query = graphql`
//   query {
//     file {
//       publicURL
//       name
//     }
//   }
// `

const IndexPage = ( ) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      allStrapiProject {
        edges {
          node {
            id
            Name
            Site
            SiteTitle
          }
        }
      }
      allStrapiSection{
        edges {
          node {
            Order
            id
            Title
            Articles {
              id
              Title
              RouteName
              Project
              SubDescription
            }
          }
        }
      }
    }
  `)

  const project = data.allStrapiProject.edges.filter(({ node }) => node.Name === PROJECT)[0]



  const header = (
    <div className={classes.container}>
      <Seo title="Главная" />
      {/* {data.file.publicURL && <img className={classes.img} src={data.file.publicURL} />} */}
      <Typography className={classes.title} align="center" variant="h2">
        Справочный центр
      </Typography>
      <Typography className={classes.subtitle} align="center" variant="h4">
        {project.node.SiteTitle}
      </Typography>
      <Search
        className={classes.search}
        label=""
        variant="outlined"
        shrink={true}
        placeholder={'Введите ключевое слово, например, "Создание заявки"'}
        innerClasses={{ menu: classes.searchMenu }}
      />
    </div>
  )

  let items = [];
  const sections = data.allStrapiSection.edges.filter(({ node }) => node.Articles.filter(value => project.node.id.endsWith(value.Project)).length > 0)
    .map(item => item.node);
  sections.map(item => item.Articles).map(a => a.filter(value => project.node.id.endsWith(value.Project)).forEach(value => items.push({
    title: value.Title,
    id: value.id,
    routeName: value.RouteName,
    description: value.SubDescription
  })));


  return (
    <Layout header={header}>
      <Articles items={items} />
    </Layout>
  )
}

export default IndexPage
